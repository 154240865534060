






























































































































































































































import Vue from "vue";
import api from "@/core/utils/api";
import { Product, User } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { StripeElements } from "@stripe/stripe-js";
import { EUVat, EUVatRate } from "@/core/plugins/eu-vat-rates";
import LocalStorage from "@/core/utils/LocalStorage";

@Component
export default class PurchaseOverview extends Vue {
  @Prop({ default: () => null }) pricingOptions!: {
    tier: string;
    cycle: string;
  };
  @Prop({ default: () => false }) loading!: boolean;
  @Prop({ default: () => 0 }) grossAmount!: number;
  @Prop({ default: () => 0 }) netTotal!: number;
  @Prop({ default: () => 0 }) grossTotal!: number;
  @Prop({ default: () => 0 }) discountPrice!: number;
  @PropSync("stepProp", { type: Number }) step!: number;
  @PropSync("checkoutEnabledProp", { type: Boolean }) checkoutEnabled!: boolean;
  @PropSync("paymentElementRefProp", {})
  paymentElementRef!: HTMLFormElement | null;
  @PropSync("couponProp", {}) coupon!: any | null;
  @PropSync("vatIdProp", { type: String }) vatId!: string;
  @PropSync("companyNameProp", { type: String }) companyName!: string;
  @PropSync("countryProp", { type: String }) country!: string;
  @PropSync("buyAsCompanyProp", { type: Boolean }) buyAsCompany!: boolean;
  @PropSync("vatValidatedProp", { type: Boolean }) vatValidated!: boolean;
  @PropSync("euVatProp", {}) euVat!: EUVatRate;
  @PropSync("vatRateProp", { type: Number }) vatRate!: number;
  @PropSync("taxExemptProp", { type: String }) taxExempt!: string;
  @PropSync("reverseChargeProp", { type: Boolean }) reverseCharge!: boolean;
  @Getter("products/products") products!: Product[];
  @Getter("profile/id") userId!: User;
  @Action("displaySnackbar") displaySnackbar!: Function;

  couponCode = "";
  subscriptionLoading = false;
  url = window.location.origin;

  get productName() {
    const product = this.products.find(
      product =>
        product.monthlyPrice === this.pricingOptions.tier ||
        product.yearlyPrice === this.pricingOptions.tier,
    );
    return product ? product.name : "";
  }

  get discountTime() {
    if (!this.coupon) return null;
    const { duration_in_months, duration } = this.coupon;
    return duration === "forever"
      ? this.$t("subscription.discountForever")
      : this.$t("subscription.discountMonths", {
          d: duration_in_months,
        }).toString();
  }

  formatPrice(amount: number) {
    return `${amount.toFixed(2)} EUR`;
  }

  formatAmount(amount: number) {
    // Use on all calculations with currency amounts per value to prevent 3rd decimal (.xx5) rounding issues of percent/tax calculations
    return parseFloat((amount / 100).toFixed(2));
  }

  cancelPurchase() {
    this.step = 1;
  }

  removeCoupon() {
    this.coupon = null;
    this.couponCode = "";
  }

  async confirmPurchase() {
    this.subscriptionLoading = true;

    let data;
    const endpoint = "/api/Purchase/PurchaseSubscription";

    const elements = (this.paymentElementRef
      ?.elements as unknown) as StripeElements;

    const addressElement = elements.getElement("address");
    await addressElement?.getValue().then(result => {
      console.log(result);

      data = {
        currency: "EUR",
        priceId: this.pricingOptions.tier,
        isGermanTaxRate: true,
        couponId: this.couponCode,
        userId: this.userId,
        city: result.value.address.city,
        country: result.value.address.country,
        addressLine1: result.value.address.line1,
        addressLine2: result.value.address.line2,
        postalCode: result.value.address.postal_code,
        state: result.value.address.state,
        phoneNumber: result.value.phone,
        vatId: this.buyAsCompany ? this.vatId : null,
        companyName: this.buyAsCompany ? this.companyName : null,
        taxExempt: this.taxExempt, // One of none, exempt, or reverse.
        preferredLocale: LocalStorage.getLocale() || "EN",
      };
    });

    try {
      const subscription = (await api.post(endpoint, data)) as any;

      console.log(subscription);

      this.displaySnackbar(this.$t("snack.stripe.subscribing"));

      const confirmIntent =
        subscription.type === "setup"
          ? this.paymentElementRef?.stripe.confirmSetup
          : this.paymentElementRef?.stripe.confirmPayment;

      // Manual elements submit method for more control on error handling & param overrides
      if (this.paymentElementRef?.stripe) {
        const { error: submitError } = await elements.submit();
        if (submitError) {
          console.error(submitError);
          return;
        }

        const { error } = confirmIntent({
          //`Elements` instance that was used to create the Payment Element
          elements: elements,
          clientSecret: subscription.clientSecret,
          confirmParams: {
            return_url: `${this.url}/subscription/checkout`,
          },
          //redirect: "if_required",
        });

        if (error) {
          console.error(error);
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          const messageContainer = document.querySelector("#error-message");
          if (messageContainer)
            messageContainer.textContent = error.message as string;
          this.subscriptionLoading = false;
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      }
    } catch (error: any) {
      console.log(error);
      const msg =
        error.response?.data?.message ||
        this.$t("snack.stripe.pfail").toString();
      this.displaySnackbar(msg);
      this.subscriptionLoading = false;
    }
  }

  async redeemCoupon() {
    if (!this.couponCode) {
      const msg = this.$t("snack.stripe.entercode").toString();
      this.displaySnackbar(msg);
      return;
    }

    try {
      const end = `/api/Coupons/${this.couponCode}`;
      this.coupon = await api.get(end);
    } catch (error: any) {
      const fallback = this.$t("snack.stripe.codeinvalid").toString();
      const msg = error.message || fallback;
      this.displaySnackbar(msg);
    }
  }

  @Watch("country", { immediate: true })
  async updateVatRate() {
    const { [this.country]: euVat } = EUVat.rates;
    this.euVat = euVat || {};
    this.$nextTick().then(() => {
      if (this.buyAsCompany && this.euVat && this.vatValidated)
        if (this.euVat.country === "Germany") {
          console.log("No Reverse Charge in DE");
          this.reverseCharge = false;
        } else if (!this.euVat.country) {
          console.log("No Reverse Charge (outside EU) in", this.country);
          this.reverseCharge = false;
        } else {
          console.log("Apply Reverse Charge in", this.country);
          this.reverseCharge = true;
        }
      else {
        console.log("No valid Company - Apply default tax in", this.country);
        this.reverseCharge = false;
      }
    });
  }
}
